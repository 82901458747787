import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const ResourceCard = makeShortcode("ResourceCard");
const DoDontExample = makeShortcode("DoDontExample");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "icons-are-visual-symbols-used-to-represent-ideas-objects-or-actions-they-communicate-messages-at-a-glance-afford-interactivity-and-draw-attention-to-important-information"
    }}>{`Icons are visual symbols used to represent ideas, objects, or actions. They communicate messages at a glance, afford interactivity, and draw attention to important information.`}</h3>
    <h2 {...{
      "id": "resources"
    }}>{`Resources`}</h2>
    <Row className="tile--group" mdxType="Row">
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Elements package: Icons" href="https://github.com/IBM/carbon-elements/tree/master/packages/icons" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
  </ResourceCard>
      </Column>
      <Column colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Elements package: Icons-React" href="https://github.com/IBM/carbon-elements/tree/master/packages/icons-react" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "98%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "github icon",
              "title": "github icon",
              "src": "/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png",
              "srcSet": ["/static/685e175fe4a49ccc584e92390bde04d2/7d71f/github-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
 </ResourceCard>
      </Column>
      <Column offsetLg="4" colLg="4" colMd="4" noGutterSm mdxType="Column">
  <ResourceCard title="Get all icons via the IBM Design Language Kit" href="sketch://add-library/cloud/75VZZ" type="resource" mdxType="ResourceCard">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "200px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "95%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "sketch icon",
              "title": "sketch icon",
              "src": "/static/c78ce9b54dbb1ba4c080ef5537a24733/7d71f/sketch-icon.png",
              "srcSet": ["/static/c78ce9b54dbb1ba4c080ef5537a24733/7d71f/sketch-icon.png 200w"],
              "sizes": "(max-width: 200px) 100vw, 200px"
            }}></img>{`
    `}</span>
 </ResourceCard> 
      </Column>
    </Row>
    <br />
    <br />
    <h2 {...{
      "id": "designing-with-icons"
    }}>{`Designing with icons`}</h2>
    <h3 {...{
      "id": "sizing"
    }}>{`Sizing`}</h3>
    <p>{`Carbon components typically use icons on 16px artboards. Icons on 20px, 24px, and 32px artboards can also be used within the UI. Be sure icon size is consistent throughout your product.`}</p>
    <img {...{
      "src": "/iconography_usage_sizing-alt-9fef21279923ce5d36fcfaa2d1398543.svg",
      "alt": "icon sizing"
    }}></img>
    <img {...{
      "src": "/iconography-usage-sizing-2-498befeeb656121ed2218a91772548c1.svg",
      "alt": "icon alignment"
    }}></img>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
  <DoDontExample correct label="Do use the correct icon size with IBM Plex." mdxType="DoDontExample">
          <img {...{
            "src": "/iconography-usage-sizing-3-ae09c60ca0353380e0b0077013776f8f.svg",
            "alt": "proper sizing"
          }}></img>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
  <DoDontExample label="Don’t alter the icon-text size ratio." mdxType="DoDontExample">
          <img {...{
            "src": "/iconography-usage-sizing-4-7c9a8c5d53c2b7fdf3ec17f3ed3334f4.svg",
            "alt": "improper sizing"
          }}></img>
  </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "touch-targets"
    }}>{`Touch targets`}</h3>
    <p>{`All touch targets for interactive icons need to be 44px or larger. Developers can add padding to a touch target with CSS to meet the 44px requirement.`}</p>
    <img {...{
      "src": "/iconography-usage-padding-6-db2285de61c2626817717dc6cf2ed9a3.svg",
      "alt": "touch target padding"
    }}></img>
    <h3 {...{
      "id": "color"
    }}>{`Color`}</h3>
    <p>{`Icons are always a solid, monochromatic color and need to pass the same color contrast ratio as typography (4.5:1). The color of the icon should reflect the importance of the icon’s action which should always be to help guide a user. For more information on color, see the `}<a parentName="p" {...{
        "href": "/guidelines/color"
      }}>{`Color guidelines`}</a>{`.`}</p>
    <p>{`It’s also important to note that Carbon v10 icons themselves do not have interaction states, only their backgrounds do.`}</p>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
  <DoDontExample correct label="Do match your icon color with your text color when pairing them." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "proper alignment",
              "title": "proper alignment",
              "src": "/static/199cc679aae7b270b1473d73fdff54ea/9eefd/iconography-color-01.png",
              "srcSet": ["/static/199cc679aae7b270b1473d73fdff54ea/d6747/iconography-color-01.png 288w", "/static/199cc679aae7b270b1473d73fdff54ea/09548/iconography-color-01.png 576w", "/static/199cc679aae7b270b1473d73fdff54ea/9eefd/iconography-color-01.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
  <DoDontExample label="Don’t use different colors for text and icons." mdxType="DoDontExample">
          <span {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1088px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "100%",
                "position": "relative",
                "bottom": "0px",
                "left": "0px",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="span" {...{
              "className": "gatsby-resp-image-image",
              "alt": "improper alignment",
              "title": "improper alignment",
              "src": "/static/6e1f880e1172ff9272fb16cd09867e10/9eefd/iconography-color-02.png",
              "srcSet": ["/static/6e1f880e1172ff9272fb16cd09867e10/d6747/iconography-color-02.png 288w", "/static/6e1f880e1172ff9272fb16cd09867e10/09548/iconography-color-02.png 576w", "/static/6e1f880e1172ff9272fb16cd09867e10/9eefd/iconography-color-02.png 1088w"],
              "sizes": "(max-width: 1088px) 100vw, 1088px"
            }}></img>{`
    `}</span>
  </DoDontExample>
      </Column>
    </Row>
    <h3 {...{
      "id": "alignment"
    }}>{`Alignment`}</h3>
    <p>{`When used next to text, icons should be center-aligned.`}</p>
    <Row mdxType="Row">
      <Column offsetLg="4" colMd="4" colLg="4" mdxType="Column">
  <DoDontExample correct label="Do center-align icons when they’re next to text." mdxType="DoDontExample">
          <img {...{
            "src": "/iconography-usage-sizing-5-1450b1f33d7dbe1e4045a5f76ca666d4.svg",
            "alt": "proper alignment"
          }}></img>
  </DoDontExample>
      </Column>
      <Column colMd="4" colLg="4" mdxType="Column">
  <DoDontExample label="Don’t baseline-align icons to the text." mdxType="DoDontExample">
          <img {...{
            "src": "/iconography-usage-sizing-6-66143a7daffbf8699e5634201f1a792c.svg",
            "alt": "improper alignment"
          }}></img>
  </DoDontExample>
      </Column>
    </Row>
    <h2 {...{
      "id": "developing-with-icons"
    }}>{`Developing with icons`}</h2>
    <h3 {...{
      "id": "svg-icons"
    }}>{`SVG Icons`}</h3>
    <h4 {...{
      "id": "requirements"
    }}>{`Requirements:`}</h4>
    <p>{`Install `}<inlineCode parentName="p">{`carbon-icons`}</inlineCode>{`.`}</p>
    <p>{`Full installation details can be found in the Carbon icons `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon-icons"
      }}>{`GitHub repo`}</a>{`.`}</p>
    <h3 {...{
      "id": "main-files"
    }}>{`Main files`}</h3>
    <p><inlineCode parentName="p">{`carbon-icons`}</inlineCode>{` ships with two main SVG files:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Filename`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Supported versions`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`carbon-icons.svg`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Contains current icons (consolidated subset of legacy icons used in IBM Bluemix)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3.x`}</inlineCode>{` & newer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`carbon-icons.json`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JSON file created from carbon-icons.svg, used in `}<a parentName="td" {...{
              "href": "/guidelines/iconography/library"
            }}>{`Carbon`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3.x`}</inlineCode>{` & newer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`carbon-icons.js`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JS module created from carbon-icons.svg, used in `}<inlineCode parentName="td">{`Icon`}</inlineCode>{` React Component in `}<a parentName="td" {...{
              "href": "#"
            }}>{`carbon-components-react`}</a></td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3.x`}</inlineCode>{` & newer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`icons.json`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`legacy JSON file created from sprite.svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`1.x`}</inlineCode>{`,`}<inlineCode parentName="td">{`2.x`}</inlineCode>{`&`}<inlineCode parentName="td">{`3.x`}</inlineCode></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`legacy-icons.js`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`JS module created from sprite.svg`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`3.x`}</inlineCode>{` only`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "accessibility"
    }}>{`Accessibility`}</h3>
    <p>{`For screen reader accessibility, provide a context-rich title for the SVG using `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` element.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg>
  <title>Add a new service</title>
  <use xlink:href="/carbon-icons/dist/icon--add--glyph"></use>
</svg>
`}</code></pre>
    <p>{`If support for older browsers is needed, use the `}<inlineCode parentName="p">{`aria-labelledby`}</inlineCode>{` attribute to reference the `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` element using an `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
    <p>{`The `}<inlineCode parentName="p">{`<title>`}</inlineCode>{` element will be read by the screen reader to the user, so it should describe its purpose.`}</p>
    <p>{`Make sure that you do not duplicate this `}<inlineCode parentName="p">{`id`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-html"
      }}>{`<svg aria-labelledby="add">
  <title id="add">Add a new service</title>
  <use xlink:href="/carbon-icons/dist/icon--add"></use>
</svg>
`}</code></pre>
    <h4 {...{
      "id": "for-more-details-on-accessibility-see-the-following-resources"
    }}>{`For more details on accessibility, see the following resources:`}</h4>
    <br />
    <ul>
      <li parentName="ul">{`“Accessible SVGs” via `}<a parentName="li" {...{
          "href": "https://css-tricks.com/accessible-svgs/"
        }}>{`CSS-Tricks`}</a></li>
      <li parentName="ul">{`“5.4 The ‘desc’ and ‘title’ elements” via `}<a parentName="li" {...{
          "href": "https://www.w3.org/TR/SVG11/struct.html#DescriptionAndTitleElements"
        }}>{`W3C.org`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      